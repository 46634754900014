<template>
  <div>
    <h3 class="p-1 mb-0">Please select your profile below to login to the Learning Centre</h3>
   
    <div v-for="person in memberList" :key="person.id" class="full-width p-1">
      <Profile
        @profileClicked="chooseMember(person)"
        :person="person"
        :profileScreen="false"
        :mobile="true"
        :email="true"
        :age="true"
      />
    </div>

    <div class="d-flex-column main mb-2 loose">
      <div class="align-text-center mw-400">
        <el-button type="primary" class="w-100 button-fat" @click="newProfile()">
          Add a New Participant
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapMutations } from "vuex";
import Profile from "@/components/profile/Profile.vue";
import msg from "@/utils/constants/msg";

export default {
  name: "OidcOauth",
  components: {
    Profile,
  },
  mounted() {
    this.$store.commit("root/LOADING", true);
    if (!this.$route.query.code) {
      if (
        this.$store.getters["user/isAuthenticated"] &&
        !this.$store.getters["verify/userRequiresVerification"]
      ) {
        this.loadMembers();
      } else if (this.$store.getters["verify/userRequiresVerification"]) {
        this.$router.push({ name: "oidc-imc" }).catch(() => {});
      } else {
        this.$router.push({ name: "oidc-imc" }).catch(() => {});
      }
    } else {
      const url = `/nrl/api/v1/portal/login?code=${this.$route.query.code}&imc=true`;
      this.$noauth
        .post(url, {})
        .then(async (response) => {
          this.$store.dispatch("user/login", response.data);
          this.loadMembers();
        })
        .catch(() => {
          this.$store.commit(
            "views/PUSH_NOTIFICATION",
            {
              msg: msg.error.unauthorized,
              type: "warning",
              route: "home",
            },
            { root: true }
          );
          this.$router.push({ name: "home" }).catch(() => {});
          this.$store.commit("root/LOADING", false);
        });
    }
  },
  data() {
    return {
      memberList: [],
    };
  },
  methods: {
    ...mapMutations("views", ["PUSH_NOTIFICATION"]),
    newProfile() {
      this.$router.push({ name: "lcnewprofile" }).catch(() => {});
    },
    chooseMember(member) {
      if (member && member.id && this.$store.getters["user/isAuthenticated"]) {
        this.$store.commit("root/LOADING", true);
        const url = "/nrl/api/v1/portal/oidc-imc/authorize";
        // eslint-disable-next-line prefer-const
        let queryData = this.$store.getters["imcSession/everything"];
        queryData.member_id = member.id;
        this.$http
          .get(url, {
            params: queryData,
          })
          .then((response) => {
            this.$store.commit("root/LOADING", false);
            window.location = response.data.redirect_uri;
          })
          .catch((error) => {
            this.PUSH_NOTIFICATION({
              msg:
                error.response && error.response.data && error.response.data.message
                  ? error.response.data.message
                  : msg.error.apiError,
              route: this.$route.name,
              type: "warning",
            });
            this.$store.commit("root/LOADING", false);
          });
      }
    },
    loadMembers() {
      if (this.$store.getters["user/isAuthenticated"]) {
        this.$store.commit("root/LOADING", true);
        this.$http
          .get("/nrl/api/v1/portal/members")
          .then((membersResponse) => {
            this.memberList = membersResponse.data.data.map(
              ({ _id: id, profile, verification }) => ({ id, verification, ...profile })
            );
            this.$store.commit("root/LOADING", false);
          })
          .catch(() => {
            this.PUSH_NOTIFICATION({
              msg: msg.error.apiError,
              route: this.$route.name,
              type: "warning",
            });
            this.$store.commit("root/LOADING", false);
          });
      }
    },
  },
};
</script>
